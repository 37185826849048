<template>
  <div id="MarqueDetails">
    <v-breadcrumbs :items="breadItems" divider=">"></v-breadcrumbs>

    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="6" md="6" class="fill-height d-flex flex-column">
          <v-card>
            <v-card-title class="headline cyan darken-1" primary-title>
              <v-icon
                @click="deleteDialog = true"
                style="
                  position: absolute;
                  top: 6px;
                  right: 15px;
                  color: red;
                  font-size: 40px;
                  cursor: pointer;
                "
                >mdi-delete-forever</v-icon
              >
              <v-dialog v-model="deleteDialog" width="500px">
                <v-card>
                  <v-card-title class="headline cyan darken-1" primary-title>
                    <h4 class="mb-3 justify-center white--text">
                      Confirmation
                    </h4>
                  </v-card-title>
                  <v-card-text>
                    <div class="text-center">
                      Êtes vous sur de supprimer la marque "
                      <span style="text-decoration: underline; color: #00acc1">
                        {{ Mark.name }}
                      </span>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red lighten-2"
                      text
                      @click="deleteDialog = false"
                    >
                      Non
                    </v-btn>
                    <v-btn
                      color="#19b4bf"
                      text
                      v-on:click.prevent="SupprimerMarque()"
                    >
                      Oui
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <h4
                style="text-align: center; margin-left: 200px"
                class="mb-3 justify-center white--text"
              >
                Paramètres Généraux
              </h4>
            </v-card-title>
            <v-card-text style="height: 650px">
              <v-container>
                <!-- Nom de la Marque  -->
                <v-col class="justify-center">
                  <v-text-field
                    cols="12"
                    sm="6"
                    v-model="Mark.name"
                    label="Nom de la Marque"
                  ></v-text-field>
                </v-col>
                <!-- Libelle de la Marque  -->
                <v-col class="justify-center">
                  <v-text-field
                    v-model="Mark.libelle"
                    label="libelle"
                    outlined
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col class="justify-center">
                  <v-row class="justify-center">
                    <!-- Level de la Marque  -->
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="Mark.level"
                        label="Level"
                        outlined
                        type="number"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <!-- Order de la Marque  -->
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="Mark.order"
                        label="Order"
                        outlined
                        type="number"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <!-- ParentId de la Marque  -->
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="Mark.parentId"
                        label="ParentId"
                        outlined
                        type="number"
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Image de la Marque  -->
                <v-col class="justify-center">
                  <v-img contain max-height="295" :src="Mark.src"></v-img>
                  <!-- Modification Image  de la Marque  -->
                  <v-file-input
                    class="justify-center"
                    hide-input
                    prepend-icon="mdi-camera"
                    v-model="images"
                    label="Remplacer l'image"
                    @change="uploadNewImageMarque()"
                    @click:clear="callItBack()"
                  ></v-file-input>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- Confirmation Modification  de la Marque  -->
              <v-btn color="blue darken-1" text @click="close()">
                Annuler
              </v-btn>
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-6"
                    color="blue darken-1"
                    text
                    v-bind="attrs"
                    v-on="on"
                    :disabled="
                      (Mark.name && Mark.name.trim() === '') ||
                      !Mark.name ||
                      (Mark.name && Mark.name.match(/^ *$/)) ||
                      (Mark.libelle && Mark.libelle.trim() === '') ||
                      !Mark.libelle ||
                      (Mark.libelle && Mark.libelle.match(/^ *$/)) ||
                      !Mark.parentId ||
                      !Mark.order ||
                      !Mark.level ||
                      !Mark.src
                    "
                  >
                    Confirmer
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline cyan darken-1" primary-title>
                    <h4 class="mb-3 justify-center white--text">
                      Confirmation
                    </h4>
                  </v-card-title>
                  <v-card-text>
                    <div class="text-center">
                      Êtes vous sur de modifier la Marque avec nouveaux nom

                      <span
                        style="text-decoration: underline; color: #00acc1"
                        >{{ Mark.name }}</span
                      >
                    </div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red lighten-2" text @click="dialog = false">
                      Non
                    </v-btn>
                    <v-btn
                      color="#19b4bf"
                      text
                      v-on:click.prevent="EditMarque()"
                    >
                      Oui
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" md="6" class="fill-height d-flex flex-column">
          <v-card>
            <v-card-title class="headline cyan darken-1" primary-title>
              <h4
                style="text-align: center; margin-left: 200px"
                class="mb-3 justify-center white--text"
              >
                Paramètre d'assignation
              </h4>
            </v-card-title>

            <div class="container" max-width="500" style="height: 712px">
              <v-col class="justify-center">
                <treeselect
                  name="treeselect1"
                  :multiple="true"
                  :options="options"
                  placeholder="Sélectionnez la liste des catégorie(s)..."
                  value-consists-of="LEAF_PRIORITY"
                  v-model="SousCategoriesList"
                />
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- Confirmation Modification  de la Marque  -->

                <v-dialog v-model="dialogSubCategory" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-6"
                      color="blue darken-1"
                      text
                      v-bind="attrs"
                      v-on="on"
                      :disabled="disabled"
                    >
                      Confirmer
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline cyan darken-1" primary-title>
                      <h4 class="mb-3 justify-center white--text">
                        Confirmation
                      </h4>
                    </v-card-title>
                    <v-card-text>
                      <div class="text-center">
                        Êtes vous sur de modifier la liste des d'assignations
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red lighten-2"
                        text
                        @click="dialogSubCategory = false"
                      >
                        Non
                      </v-btn>
                      <v-btn
                        color="#19b4bf"
                        text
                        v-on:click.prevent="ConfirmerAssignedSubCategorys()"
                      >
                        Oui
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
              <v-col class="justify-center">
                <treeselect
                  name="treeselect2"
                  :options="result"
                  :disable-branch-nodes="true"
                  :show-count="true"
                  placeholder="Sélectionnez une sous catégorie"
                  v-model="SelectedSousCategorie"
                  @input="getFamillelist(SelectedSousCategorie)"
                />
              </v-col>
              <v-col class="justify-center">
                <v-btn
                  :disabled="
                    (SelectedSousCategorie &&
                      SelectedSousCategorie.trim() === '') ||
                    !SelectedSousCategorie
                  "
                  @click="NewFamilyDialog = true"
                  small
                  class="my-2"
                  dark
                  color="primary"
                >
                  Ajoute Nouvelle Famille
                </v-btn>
              </v-col>
              <v-virtual-scroll :items="items" :item-height="50" height="300">
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        @click="editFamille(item)"
                        depressed
                        color="primary"
                        small
                      >
                        Modifier Famille

                        <v-icon color="#ea3490" right> mdi-pencil </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
              <EditFamilyModal
                :EditFamilyDialog.sync="EditFamilyDialog"
                :Family="sendFamilyInfo"
                @Refrech="getFamillelist(SelectedSousCategorie)"
              />

              <NewFamilyModal
                :NewFamilyDialog.sync="NewFamilyDialog"
                @Refrech="getFamillelist(SelectedSousCategorie)"
                :Mark="Mark"
                :SelectedSousCategorie="SelectedSousCategorie"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { firebase } from "@firebase/app";
import Vue from "vue";
import NewFamilyModal from "../../admin/components/core/NewFamilyModal.vue";
import EditFamilyModal from "../../admin/components/core/EditFamilyModal.vue";
import NodesName from "../../utils/nodesName";
import NodesFieldName from "../../utils/nodesFieldName";
import "firebase/firestore";
import db from "../../../firebaseInit";
import Multiselect from "vue-multiselect";
import nodesFieldName from "../../utils/nodesFieldName";
import FunctionsUtils from "../../utils/functions";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "MarqueDetails",

  components: { Treeselect, NewFamilyModal, EditFamilyModal },

  data() {
    return {
      sendFamilyInfo: [],
      EditFamilyDialog: false,
      NewFamilyDialog: false,
      result: [],
      CategoriesList: [],
      items: [],
      SousCategoriesList: [],
      SelectedSousCategorie: null,
      originalSousCategories: [],
      options: [],
      optionsSub: [],
      Mark: [],
      deleteDialog: false,
      isEmpty: false,
      images: [],
      userID: null,
      images: [],
      image: [],
      dialog: false,
      SousCategoriesListmap: new Map(),
      dialogSubCategory: false,
      breadItems: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Gestion des Marques",
          disabled: false,
          to: "/Mercarue/markManagement/MarkManagement",
        },
      ],
    };
  },
  // Importation UserID
  async beforeMount() {
    this.userID = localStorage.getItem("adminID");
  },

  async beforeRouteEnter(to, from, next) {
    if (to.params.Mark.assignedCategoryId.length > 0) {
      db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.UNIQUE_ID, "==", to.params.Mark.id)
        .where(
          NodesFieldName.CATEGORY,
          "array-contains-any",
          to.params.Mark.assignedCategoryId
        ) // Up to 10 category max
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size == 1) {
            querySnapshot.forEach((doc) => {
              next((vm) => {
                vm.originalSousCategories = doc.data().subCategory;
                vm.SousCategoriesList = doc.data().subCategory;
                vm.Mark = to.params.Mark;
              });
            });
          }
        });
    } else {
      next((vm) => {
        vm.Mark = to.params.Mark;
      });
    }
  },

  methods: {
    async SupprimerMarque() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Mark.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                deleted: true,
                deletedBy: this.userID,
                deleteDate: new Date(),
              })
              .then(() => {
                this.$router.push({
                  name: "MarkManagement",
                });
              });
          });
        });
    },
    //Upload Image dans Firebase Storage
    async uploadNewImageMarque() {
      await firebase
        .storage()
        .ref("mark_logo/" + this.Mark.name + "/" + "/" + this.images.name)
        .put(this.images)
        .then((response) => {
          firebase
            .storage()
            .ref("mark_logo/" + this.Mark.name + "/" + "/" + this.images.name)
            .getDownloadURL()
            .then((url) => {
              this.Mark.src = url;
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    },
    //Modification Marque Info (Nom, Libelle, Level, Order, ParentId, Image)
    EditMarque() {
      db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Mark.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                name: FunctionsUtils.capitalizeFirstLetter(this.Mark.name),
                image: this.Mark.src,
                level: Number(this.Mark.level),
                order: Number(this.Mark.order),
                parentId: Number(this.Mark.parentId),
                libelle: FunctionsUtils.capitalizeFirstLetter(
                  this.Mark.libelle
                ),
                updatedBy: this.userID,
                updatedDate: new Date(),
              })
              .then(() => {
                this.dialog = false;
              });
          });
        });
    },
    async editFamille(value) {
      this.sendFamilyInfo = value;
      this.EditFamilyDialog = true;
    },

    async getFamillelist(value) {
      this.items = [];
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_FAMILY)
        .where(NodesFieldName.SUBCATEGORY, "==", value)
        .where(NodesFieldName.MARK, "==", this.Mark.id)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.items.push({
              name: doc.data().name,
              id: doc.data().uniqueId,
              order: doc.data().order,
              parentId: doc.data().parentId,
              libelle: doc.data().libelle,
              level: doc.data().level,
            });
          });
        });
    },
    async ConfirmerAssignedSubCategorys() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Mark.id)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                subCategory: this.SousCategoriesList,
              })
              .then(() => {
                this.originalSousCategories = this.SousCategoriesList;
                this.getSubCategories();
                this.close();
              });
          });
        });
    },
    close() {
      this.dialogSubCategory = false;
    },
    async getSubCategories() {
      this.result = [];
      this.SelectedSousCategorie = null;
      if (this.originalSousCategories) {
        for (var i = 0; i < this.originalSousCategories.length; i++) {
          this.result.push({
            label: this.SousCategoriesListmap.get(
              this.originalSousCategories[i]
            ),
            id: this.originalSousCategories[i],
          });
        }
      }
    },
    async getCategories() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
        .where(NodesFieldName.DELETED, "==", false)
        .orderBy(NodesFieldName.ORDER, "asc")
        .where(
          NodesFieldName.NAME,
          "in",
          this.$route.params.Mark.assignedCategoryName
        ) // Up to 10 category max
        .get()
        .then((querySnapshot) => {
          let i = 0;
          querySnapshot.forEach((doc) => {
            this.options.push({
              label: doc.data().libelle,
              id: doc.data().uniqueId,
              children: [],
            });

            db.collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
              .where(NodesFieldName.DELETED, "==", false)

              .where(NodesFieldName.CATEGORY, "==", doc.id)
              .orderBy(NodesFieldName.ORDER, "asc")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((ref) => {
                  this.options[i].children.push({
                    label: ref.data().libelle,
                    id: ref.data().uniqueId,
                    cat: doc.id,
                  });
                });
                i++;
              });
          });
        });
    },
    async getallsub() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.SousCategoriesListmap.set(
              doc.data().uniqueId,
              doc.data().libelle
            );
          });
          this.getSubCategories();
        });
    },
  },
  created() {
    this.getallsub();
    if (this.$route.params.Mark.assignedCategoryId.length > 0) {
      this.originalSousCategories = this.$route.params.Mark.assignedSubCategory;
      this.getCategories();
    }
  },
  computed: {
    disabled() {
      const compareArrays = (a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
      };
      return compareArrays(
        this.SousCategoriesList,
        this.originalSousCategories
      );
    },
  },
};
</script>
