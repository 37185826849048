<template>
  <v-layout row justify-center>
    <v-dialog v-model="NewFamilyDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline cyan darken-1" primary-title>
          <h4 class="mb-3 justify-center white--text">
            Ajoute Nouvelle Famille
          </h4>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-col class="justify-center">
                <v-text-field
                  cols="12"
                  sm="6"
                  v-model="FamilyName"
                  label="Nom de la Famille"
                ></v-text-field>
              </v-col>
              <v-col class="justify-center">
                <v-text-field
                  v-model="FamilyLibelle"
                  label="libelle"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col class="justify-center">
                <v-row class="justify-center">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="FamilyLevel"
                      label="Level"
                      outlined
                      type="number"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="FamilyParentId"
                      label="ParentId"
                      outlined
                      type="number"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="justify-center">
                <v-row class="justify-center">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="FamilyOrder"
                      label="Order"
                      outlined
                      type="number"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()"> Annuler </v-btn>

          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-6"
                color="blue darken-1"
                text
                v-bind="attrs"
                v-on="on"
                :disabled="
                  !valid ||
                  (FamilyName && FamilyName.trim() === '') ||
                  !FamilyName ||
                  (FamilyName && FamilyName.match(/^ *$/)) ||
                  (FamilyOrder && FamilyOrder.trim() === '') ||
                  !FamilyOrder ||
                  (FamilyOrder && FamilyOrder.match(/^ *$/)) ||
                  (FamilyLevel && FamilyLevel.trim() === '') ||
                  !FamilyLevel ||
                  (FamilyLevel && FamilyLevel.match(/^ *$/)) ||
                  (FamilyLibelle && FamilyLibelle.trim() === '') ||
                  !FamilyLibelle ||
                  (FamilyLibelle && FamilyLibelle.match(/^ *$/)) ||
                  (FamilyParentId && FamilyParentId.trim() === '') ||
                  !FamilyParentId ||
                  (FamilyParentId && FamilyParentId.match(/^ *$/))
                "
              >
                Confirmer
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline cyan darken-1" primary-title>
                <h4 class="mb-3 justify-center white--text">Confirmation</h4>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  Êtes vous sur d'ajouter une nouvelle Famille portant le nom

                  <span style="text-decoration: underline; color: #00acc1">{{
                    FamilyName
                  }}</span>
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="dialog = false">
                  Non
                </v-btn>
                <v-btn
                  color="#19b4bf"
                  text
                  v-on:click.prevent="addFamilyInFirebase()"
                >
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { firebase } from "@firebase/app";
import NodesName from "../../../utils/nodesName";
import NodesFieldName from "../../../utils/nodesFieldName";
import "firebase/firestore";
import db from "../../../../firebaseInit";
import FunctionsUtils from "../../../utils/functions";

export default {
  name: "AddMarque",
  data() {
    return {
      userID: null,
      dialog: false,
      FamilyName: null,
      FamilyLibelle: null,
      FamilyLevel: null,
      FamilyOrder: null,
      FamilyParentId: null,
      valid: false,
    };
  },
  props: {
    NewFamilyDialog: {
      default: false,
    },
  },
  async beforeMount() {
    this.userID = localStorage.getItem("adminID");
  },
  methods: {
    async addFamilyInFirebase() {
      var FirebaseFamilyRef = db
        .collection(NodesName.PRODUCT_TO_SHARE_FAMILY)
        .doc();
      db.collection(NodesName.PRODUCT_TO_SHARE_FAMILY)
        .where(
          NodesFieldName.NAME,
          "==",
          FunctionsUtils.capitalizeFirstLetter(this.FamilyName)
        )
        .where(NodesFieldName.DELETED, "==", false)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size == 0) {
            FirebaseFamilyRef.set({
              name: FunctionsUtils.capitalizeFirstLetter(this.FamilyName),
              deleted: false,
              uniqueId: FirebaseFamilyRef.id,
              createdBy: this.userID,
              creationDate: new Date(),
              level: Number(this.FamilyLevel),
              order: Number(this.FamilyOrder),
              parentId: Number(this.FamilyParentId),
              libelle: FunctionsUtils.capitalizeFirstLetter(this.FamilyLibelle),
              subCategory: this.SelectedSousCategorie,
              mark: this.Mark.id,
            })
              .then(() => {
                this.dialog = false;
                this.close();
                this.$emit("Refrech");
              })
              .catch(function (error) {
                console.error("Error adding document: ", error);
              });
          } else {
            confirm("la Famille existe déjà ");
            this.dialog = false;
          }
        });
    },

    close() {
      this.FamilyName = null;
      this.FamilyLibelle = null;
      this.FamilyLevel = null;
      this.FamilyOrder = null;
      this.FamilyParentId = null;
      this.$emit("update:NewFamilyDialog", false);
    },
  },
  props: {
    Mark: {
      required: true,
    },
    NewFamilyDialog: {
      type: Boolean,
    },
    SelectedSousCategorie: {
      required: true,
    },
  },
};
</script>
