<template>
  <v-layout row justify-center>
    <v-dialog v-model="EditFamilyDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline cyan darken-1" primary-title>
          <v-icon
            @click="deleteDialog = true"
            style="
              position: absolute;
              top: 25px;
              right: 15px;
              color: red;
              font-size: 40px;
              cursor: pointer;
            "
            >mdi-delete-forever</v-icon
          >
          <v-dialog v-model="deleteDialog" width="500px">
            <v-card>
              <v-card-title class="headline cyan darken-1" primary-title>
                <h4 class="mb-3 justify-center white--text">Confirmation</h4>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  Êtes vous sur de supprimer la Famille "
                  <span style="text-decoration: underline; color: #00acc1">
                    {{ Family.name }}
                  </span>
                </div>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="deleteDialog = false">
                  Non
                </v-btn>
                <v-btn
                  color="#19b4bf"
                  text
                  v-on:click.prevent="SupprimerFamille()"
                >
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <h4 class="mb-3 justify-center white--text">
            Modification Famille <v-spacer></v-spacer>" {{ Family.name }}"
          </h4>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- Nom de la Famille  -->
            <v-col class="justify-center">
              <v-text-field
                cols="12"
                sm="6"
                v-model="Family.name"
                label="Nom de la Marque"
              ></v-text-field>
            </v-col>
            <!-- Libelle de la Famille  -->
            <v-col class="justify-center">
              <v-text-field
                v-model="Family.libelle"
                label="libelle"
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="justify-center">
              <v-row class="justify-center">
                <!-- Level de la Famille  -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="Family.level"
                    label="Level"
                    outlined
                    type="number"
                    clearable
                  ></v-text-field>
                </v-col>
                <!-- Order de la Famille  -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="Family.order"
                    label="Order"
                    outlined
                    type="number"
                    clearable
                  ></v-text-field>
                </v-col>
                <!-- ParentId de la Famille  -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="Family.parentId"
                    label="ParentId"
                    outlined
                    type="number"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!-- Image de la Famille  -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Confirmation Modification  de la Famille  -->
          <v-btn color="blue darken-1" text @click="close()"> Annuler </v-btn>
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-6"
                color="blue darken-1"
                text
                v-bind="attrs"
                v-on="on"
                :disabled="
                  (Family.name && Family.name.trim() === '') ||
                  !Family.name ||
                  (Family.name && Family.name.match(/^ *$/)) ||
                  (Family.libelle && Family.libelle.trim() === '') ||
                  !Family.libelle ||
                  (Family.libelle && Family.libelle.match(/^ *$/)) ||
                  !Family.parentId ||
                  !Family.order ||
                  !Family.level
                "
              >
                Confirmer
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline cyan darken-1" primary-title>
                <h4 class="mb-3 justify-center white--text">Confirmation</h4>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  Êtes vous sur de modifier la Marque avec nouveaux nom

                  <span style="text-decoration: underline; color: #00acc1">{{
                    Family.name
                  }}</span>
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="dialog = false">
                  Non
                </v-btn>
                <v-btn color="#19b4bf" text v-on:click.prevent="EditMarque()">
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { firebase } from "@firebase/app";
import NodesName from "../../../utils/nodesName";
import NodesFieldName from "../../../utils/nodesFieldName";
import "firebase/firestore";
import db from "../../../../firebaseInit";
import FunctionsUtils from "../../../utils/functions";

export default {
  name: "AddMarque",
  data() {
    return {
      deleteDialog: false,

      userID: null,

      dialog: false,
    };
  },
  // Importation UserID
  async beforeMount() {
    this.userID = localStorage.getItem("adminID");
  },
  methods: {
    async SupprimerFamille() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_FAMILY)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Family.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                deleted: true,
                deletedBy: this.userID,
                deleteDate: new Date(),
              })
              .then(() => {
                this.$emit("update:EditFamilyDialog", false);
                this.$emit("Refrech");
              });
          });
        });
    },

    // Confirmation Modification Famille
    EditMarque() {
      db.collection(NodesName.PRODUCT_TO_SHARE_FAMILY)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Family.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                name: FunctionsUtils.capitalizeFirstLetter(this.Family.name),
                level: Number(this.Family.level),
                order: Number(this.Family.order),
                parentId: Number(this.Family.parentId),
                libelle: FunctionsUtils.capitalizeFirstLetter(
                  this.Family.libelle
                ),
                updatedBy: this.userID,
                updatedDate: new Date(),
              })
              .then(() => {
                this.$emit("update:EditFamilyDialog", false);
                this.$emit("Refrech");
              });
          });
        });
    },

    //Fermeture du Dialog de modification
    close() {
      this.$emit("update:EditFamilyDialog", false);
      this.$emit("Refrech");
    },
  },
  props: {
    Family: {
      required: true,
    },
    EditFamilyDialog: {
      type: Boolean,
    },
  },
};
</script>
